<template>
  <el-tabs v-model="activeName" @tab-click="handleTabClick">
    <el-tab-pane label="地址分析" name="first">
      <getSearch v-if="activeName === 'first'" :key="getSearchKey" :category="category" :address="address" />
    </el-tab-pane>
    <el-tab-pane class="haslist-box" label="交易分析" name="second">
      <hasList v-if="activeName === 'second'" :key="hasListKey" />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import getSearch from '@/components/getSearch.vue';
import hasList from '@/components/hasList.vue';

export default {
  data() {
    return {
      activeName: 'first', // 默认值
      getSearchKey: 0,
      hasListKey: 0,
      category: '',
      address: ''
    };
  },
  components: {
    getSearch,
    hasList,
  },
  methods: {
    handleTabClick(tab) {
      // 保存当前选中的标签页名称到 localStorage
      const { category, address } = this.$route.params;

      if (category && address && tab.props.name === 'second') {
        // this.activeName = 'second';
        window.open('/', '_self')
      } else {
        // 保存当前选中的标签页名称到 localStorage
        localStorage.setItem('activeTab', tab.props.name);
      }
    }
  },
  created() {
    // 使用 Vue Router 的 $route.params 获取路径参数
    const { category, address } = this.$route.params;
    if (category && address) {
      // URL 包含参数，设置为 'first' 标签页并更新参数
      this.activeName = 'first';
      this.category = category;
      this.address = address;
      // 清除 URL 中的参数
      window.history.replaceState(null, null, this.$route.path);
    } else {
      // 从 localStorage 中获取选中的标签页
      const savedTab = localStorage.getItem('activeTab');
      if (savedTab) {
        this.activeName = savedTab;
      }
    }
  },
  watch: {
    // 监听 activeName 变化并保存到 localStorage
    activeName(newName) {
      localStorage.setItem('activeTab', newName);
      if (newName === 'first') {
        this.getSearchKey += 1; // 刷新 getSearch 组件数据
      } else if (newName === 'second') {
        this.hasListKey += 1; // 刷新 hasList 组件数据
      }
    }
  }
};
</script>


<style scoped>
.el-tabs__header {
  margin: 0 auto;
  margin: 0 0 15px;
  padding: 0;
  position: relative;
  width: 80%;
}

:deep(.el-tabs__nav-scroll) {
  display: flex;
  justify-content: center;
}

/* 去掉长分割线 */
:deep(.el-tabs__nav-wrap::after) {
  position: static !important;
}


/* 去掉下划线 */
:deep(.el-tabs__active-bar) {
  background-color: transparent !important;
}

/* 未选中状态的样式 */
:deep(.el-tabs__item) {
  color: #000;
  padding-right: unset !important;
  padding-left: unset !important;
  border-radius: 30px;
  border: 1px solid #000;
  margin-right: 2rem;
  width: 135px;
  height: 4rem;
  box-sizing: unset;
}

/* 选中状态的样式 */
:deep(.el-tabs__item.is-active) {
  border: 1px solid #000;
  border-radius: 30px;
  width: 160px;
  background: #000;
  color: #fff;
  font-size: 21px;
  height: 4rem;
  font-weight: bold;
}

/* 选中状态的样式 */
:deep(.el-tabs__item.is-active:hover) {
  color: #fff;
}

::v-deep .el-tabs__item {
  padding: 0;
  border: 1px solid #000;
  border-radius: 30px;
  width: 160px;
  color: #000;
  font-size: 21px;
  height: 4rem;
  font-weight: bold;
}

:deep(.el-tabs__item:hover) {
  color: black;
}

@media screen and (max-width: 768px) {
  ::v-deep .el-tabs__item {
    border: 1px solid #000;
    border-radius: 16px;
    width: 120px;
    font-size: 14px;
    height: 2.5rem;
    font-weight: bold;
  }

  :deep(.el-tabs__item.is-active) {
    border-radius: 16px;
    width: 120px;
    font-size: 14px;
    height: 2.5rem;
  }

  :deep(.el-tabs__nav) {
    left: 15px;
  }
}
</style>
