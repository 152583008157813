// main.js
import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import router from './router';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const head = createHead();


// 全局注册自定义指令
app.directive('longpress', {
  // 绑定指令到元素
  bind(el, binding) {
    let timer = null;

    // 添加触摸开始事件
    el.addEventListener('touchstart', () => {
      timer = setTimeout(() => {
        if (typeof binding.value === 'function') {
          binding.value();
        }
      }, 500); // 长按时间设置为500ms
    });

    // 添加触摸结束事件
    el.addEventListener('touchend', () => {
      clearTimeout(timer);
    });
  }
});


app.use(ElementPlus);
app.use(router);
app.use(head); // 使用 vueuse/head
app.mount('#app');
