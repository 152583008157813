<template>
    <div class="getSearch-container">

        <!-- 搜索模块 -->
        <div class="getSearch-box">
            <el-row :gutter="20" style="height:4rem">
                <el-col :span="24">
                    <ul class="getSearch-ul">
                        <li class="getSearch-selected">
                            <el-select v-model="selectedChain" placeholder="请选择链">
                                <el-option v-for="chain in chains" :key="chain.value" :label="chain.label" :value="chain.label">
                                    <template v-slot:default>
                                        <img :src="chain.img" alt="" class="chain-icon" />
                                        <span>{{ chain.label }}</span>
                                    </template>
                                </el-option>
                                <template v-slot:prefix>
                                    <img :src="selectedChainImg" alt="" class="chain-icon" v-if="selectedChainImg"/>
                                </template>
                            </el-select>
                        </li>
                        <li class="getSearch-input">
                            <el-input v-model="searchKey" placeholder="输入或选择地址开始搜索" v-longpress="handleLongPress" clearable @clear="handleClear" style="width:100%;"></el-input>
                        </li>
                        <li class="getSearch-button">
                            <el-button :disabled="isDisabled" size="large"  @click="searchData" ><el-icon><Search /></el-icon><span class="getSerach-button-span">开始分析</span></el-button>
                            
                        </li>
                    </ul>

                </el-col>
            </el-row>
        </div>
        <el-divider></el-divider>

        <div class="getCases-list">
            <div class="clear-getcases">
                <div class="cases-top-box">
                    <span class="cases-title">典型案例：</span>
                    <el-tooltip v-for="(item, index) in cases" :key="index" class="item" effect="dark" :content="item.content" placement="top-start" disabled>
    
                        <el-button 
                        :class="{ active: buttonActiveClass(item.caseName, item.chainShortName, item.address) }" 
                        class="cases-button" 
                        @click="showData(item.caseName, item.chainShortName, item.address)">
                        {{ item.caseName }}
                        </el-button>

                    </el-tooltip>
                </div>
            
                <div class="cases-bottom-box">
                    <span class="cases-title-bottom">名人地址：</span>
                    <el-tooltip v-for="(person, index) in persons" disabled :key="index" class="item" effect="dark" :content="person.content" placement="top-start">
                        <el-button 
                        :class="{ active: buttonActiveClass(person.caseName, person.chainShortName, person.address) }" 
                        class="cases-button" 
                        @click="showData(person.caseName, person.chainShortName, person.address)">
                        {{ person.caseName }}
                        </el-button>


                        
                    </el-tooltip>
                </div>
            </div>
        </div>
        <el-divider v-if="isMobile"></el-divider>
        <!-- 2.风险评分和地址概述 -->
        <div 
        v-loading="fullscreenLoading"
        element-loading-svg='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32m0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32m448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32m-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32M195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0m-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>'
        element-loading-background="#fff"
        >
            <div class="getSearch-text-one" v-if="getRiskList">
                <el-row>
                    <el-col class="detail-top-box-title" :span="2">
                        <img :src="getSelectionImage" alt="" class="chains-icon" v-if="getSelectionImage"/>
                    </el-col>
                    <el-col :span="22">
                        <el-text class="chains-name-text">{{selectedChain}}</el-text>|
                        <el-text class="chains-address-text">{{ addressTypeLabel }}</el-text>
                        <p class="chains-address-keys">
                            {{ searchKey }}
                            <el-tooltip class="item" effect="dark" content="复制地址" placement="top">
                            <el-icon @click="copyAddress(searchKey)">
                                <copy-document />
                            </el-icon>
                            </el-tooltip>
                        </p>
                    </el-col>
                </el-row>
    
                <el-divider v-if="isMobile"></el-divider>
                <el-row>
                    <el-col :span="12" :xs="24">
                        <h1>AML 风险评分</h1>
                        <el-row>
                            <el-col :span="3" :xs="12">
                                <div class="red-risk-box">
                                    <p :class="riskTextClass" class="red-risk-text-one">{{ translatedRiskLevel }}</p>
                                    <p :class="riskClass" class="risk-text-two-p">{{ riskScore }}</p>
                                </div>
                            </el-col>

                            <el-col :span="21" :xs="24">
                                <div id="main" style="width: 500px; height: 300px;text-align: left;"></div>
                            </el-col>
                        </el-row>
    
                        <el-row class="getSearch-detail-box" v-if="getRiskMessage.length !== 0">
                            <el-col :span="21" :xs="21">
                                <div class="tag-container">
                                    <el-tag
                                    v-for="(tag, index) in visibleTags"
                                    :key="index"
                                    type="danger"
                                    class="tag-item"
                                    :style="{ margin: '8px' }"
                                    >
                                    {{ tag }}
                                    </el-tag>
                                    <span v-if="isMobile && hiddenTagsCount > 0" class="ellipsis">...</span>
                                </div>
                            </el-col>
                            <el-col :span="3" :xs="3" class="detail-box-right">
                                <el-button class="detail-button-box" @click="openDialog" type="text">详情</el-button>
                                <el-dialog
                                    v-model="dialogVisible"
                                    title="风险评分详情"
                                    :lock-scroll="lockScroll"
                                    center
                                    @close="handleClose">
    
                                    <el-table
                                    :data="getRiskDetails"
                                    >
                                        <el-table-column prop="type" label="风险类型">
                                        </el-table-column>
                                        <el-table-column label="钱包地址/风险标签">
                                            <template #default="{ row }">
    
                                                <span>{{ shortenAddress(row.address) }} ({{ row.label }})</span>
    
                                                <el-tooltip class="item" effect="dark" content="复制地址" placement="top">
                                                    <el-icon @click="copyAddress(row.address)">
                                                        <copy-document />
                                                    </el-icon>
                                                </el-tooltip>
                                                <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
                                                    <el-icon @click="openInNewWindow(selectedChain,row.address)">
                                                        <Link />
                                                    </el-icon>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="转账数额(USD)/百分比">
                                            <template #default="{ row }">
                                                {{ row.volume }}/{{ row.percent }}%
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-dialog>
                            </el-col>
                        </el-row>
    
                    </el-col>
                    <el-divider v-if="isMobile"></el-divider>
                    <el-col :span="12" :xs="24">
                        <h1>地址概述</h1>
                        <el-row>
                            <el-col class="address" :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>余额</p>
                                <p class="grid-text">{{ this.getChainsList.balance }} {{ selectedChainText }}</p>
                            </el-col>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>交易总次数</p>
                                <p class="grid-text">{{ this.getChainsList.txs_count }}</p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>首次交易时间 (UTC+8)</p>
                                <p class="grid-text">{{ this.getChainsList.first_seen }}</p>
                            </el-col>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>最近交易时间 (UTC+8)</p>
                                <p class="grid-text">{{ this.getChainsList.last_seen }}</p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>收入总额</p>
                                <p class="grid-text">{{ this.getChainsList.total_received }} {{ selectedChainText }}</p>
                            </el-col>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>支出总额</p>
                                <p class="grid-text">{{ this.getChainsList.total_spent }} {{ selectedChainText }}</p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>收入次数</p>
                                <p class="grid-text">{{ this.getChainsList.received_txs_count }}</p>
                            </el-col>
                            <el-col :span="12" :xs="12">
                                <p class="grid-title"><el-icon><CaretRight /></el-icon>支出次数</p>
                                <p class="grid-text">{{ this.getChainsList.spent_txs_count }}</p>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
            <div class="getSearch-list-box" v-if="totalIn" v-loading="fullscreenLoadingA" element-loading-svg='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" ><path fill="currentColor" d="M512 64a32 32 0 0 1 32 32v192a32 32 0 0 1-64 0V96a32 32 0 0 1 32-32m0 640a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V736a32 32 0 0 1 32-32m448-192a32 32 0 0 1-32 32H736a32 32 0 1 1 0-64h192a32 32 0 0 1 32 32m-640 0a32 32 0 0 1-32 32H96a32 32 0 0 1 0-64h192a32 32 0 0 1 32 32M195.2 195.2a32 32 0 0 1 45.248 0L376.32 331.008a32 32 0 0 1-45.248 45.248L195.2 240.448a32 32 0 0 1 0-45.248zm452.544 452.544a32 32 0 0 1 45.248 0L828.8 783.552a32 32 0 0 1-45.248 45.248L647.744 692.992a32 32 0 0 1 0-45.248zM828.8 195.264a32 32 0 0 1 0 45.184L692.992 376.32a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0m-452.544 452.48a32 32 0 0 1 0 45.248L240.448 828.8a32 32 0 0 1-45.248-45.248l135.808-135.808a32 32 0 0 1 45.248 0z"></path></svg>' element-loading-background="#fff">
                <el-divider v-if="isMobile"></el-divider>
                <h1 class="getSearch-list-title">交易分析</h1>
                <el-row>
                    <el-col :span="24">
                        <h3>转入交易</h3>
                        <el-table :data="displayInData" style="width: 100%" border>
                        <el-table-column label="发送地址">
                            <template #default="{ row }">
                            <span>{{ shortenAddress(row.address) }} ({{ row.label }})</span>
                            <el-tooltip class="item" effect="dark" content="复制地址" placement="top">
                                <el-icon @click="copyAddress(row.address)">
                                <copy-document />
                                </el-icon>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
                                <el-icon @click="openInNewWindow(selectedChain, row.address)">
                                <Link />
                                </el-icon>
                            </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tx_hash_count" label="交易次数"></el-table-column>
                        <el-table-column prop="amount" :label="selectedChain"></el-table-column>
                        </el-table>
    
                        <!-- 转入交易的分页组件 -->
                        <el-pagination
                        @current-change="handleInPageChange"
                        :current-page="currentInPage"
                        :page-size="pageSize"
                        :total="totalIn"
                        background
                        layout="prev, pager, next"
                        style="margin-top: 20px;"
                        ></el-pagination>
    
                        <!-- 转出数据表格 -->
                        <h3>转出交易</h3>
                        <el-table :data="displayOutData" style="width: 100%" border>
                        <el-table-column label="接收地址">
                            <template #default="{ row }">
                            <span class="detail-in-text">{{ shortenAddress(row.address) }} ({{ row.label }})</span>
                            <el-tooltip class="item" effect="dark" content="复制地址" placement="top">
                                <el-icon @click="copyAddress(row.address)">
                                <copy-document />
                                </el-icon>
                            </el-tooltip>
                            <el-tooltip class="item" effect="dark" content="查看详情" placement="top">
                                <el-icon @click="openInNewWindow(selectedChain, row.address)">
                                <Link />
                                </el-icon>
                            </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="tx_hash_count" label="交易次数"></el-table-column>
                        <el-table-column prop="amount" :label="selectedChain"></el-table-column>
                        </el-table>
    
                        <!-- 转出交易的分页组件 -->
                        <el-pagination
                        @current-change="handleOutPageChange"
                        :current-page="currentOutPage"
                        :page-size="pageSize"
                        :total="totalOut"
                        background
                        layout="prev, pager, next"
                        style="margin-top: 20px;"
                        ></el-pagination>
                    </el-col>
                </el-row>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import * as echarts from 'echarts';
// import { ElMessageBox } from 'element-plus';
export default {
    props: {
      category: {
          type: String,
          required: true,
      },
      address: {
          type: String,
          required: true,
      },
    },
    data() {
        return {
            selectedChain: '', // 当前选中的链
            searchKey: '', // 搜索框的输入值

            currentCase: '',
            currentChain: '',
            currentAddress: '',

            chains: [
                { value: 'eth', label: 'ETH', img: require('../assets/eth.png') },
                { value: 'tron', label: 'USDT-TRC20', img: require('../assets/trx.png') },
                { value: 'ethereum', label: 'USDT-ERC20', img: require('../assets/erc.png') },
                { value: 'BTC', label: 'BTC', img: require('../assets/btc.png') }
            ],
            apiKey: 'H2ncD7G6RBYUEPbkFXhgulmN3WOazAxQ', 
            getChainsList:'',
            getRiskList:'',
            getRiskMessage:'',//风险标签 
            isMobile: window.innerWidth <= 768,
            maxVisibleTagsMobile: 2,
            getRiskDetails:[],
            getRiskDetails_score:null,
            dialogVisible: false,
            typeMap: {
                suspected_malicious: '疑似恶意',
                high_risk: '高风险',
                malicious: '恶意',
                medium_risk: '中等风险'
            },
            riskScore: 0,
            translatedRiskLevel: '',
            riskClass: '', // 用于风险的背景色
            riskTextClass: '', // 用于风险文字颜色
            lockScroll:true,
            fullscreenLoading: false,
            fullscreenLoadingA: false,
            getSelectionImage:'',
            blockchainType: '', // 当前区块链类型
            addressTypeLabel: '', // 地址类型标签
            allOutData: [], // 存储所有转出交易数据
            allInData: [], // 存储所有转入交易数据
            displayOutData: [], // 当前页显示的转出交易数据
            displayInData: [], // 当前页显示的转入交易数据
            currentInPage: 1, // 转入交易的当前页码
            currentOutPage: 1, // 转出交易的当前页码
            pageSize: 10, // 每页显示的条目数
            totalIn: 0, // 转入交易总条目数
            totalOut: 0, // 转出交易总条目数
            cases: [
                { caseName: 'USDT 诈骗案', chainShortName: 'USDT-TRC20', address: 'TWKeF4kETmaa6jHr3sX3bt2iRp9aitX9mJ', content: 'TWKeF4kETmaa6jHr3sX3bt2iRp9aitX9mJ' },
                { caseName: 'Bitkeep 黑客盗币', chainShortName: 'ETH', address: '0x0a52ecaa61268c6a5cf9cd6b1378531a4672601b', content: '0x0a52ecaa61268c6a5cf9cd6b1378531a4672601b' }
            ],
            persons: [
                { caseName: 'V 神', chainShortName: 'ETH', address: '0xab5801a7d398351b8be11c439e05c5b3259aec9b', content: '0xab5801a7d398351b8be11c439e05c5b3259aec9b' },
                { caseName: '周杰伦', chainShortName: 'ETH', address: '0x1087f515f7faae8b35045e91092ea8878b757849', content: '0x1087f515f7faae8b35045e91092ea8878b757849' },
                { caseName: '孙宇晨', chainShortName: 'ETH', address: '0x3DdfA8eC3052539b6C9549F12cEA2C295cfF5296', content: '0x3DdfA8eC3052539b6C9549F12cEA2C295cfF5296' },
                { caseName: '陈冠希', chainShortName: 'ETH', address: '0xba9193fe0768008d1928a23a31f1ddb0b1d2ec53', content: '0xba9193fe0768008d1928a23a31f1ddb0b1d2ec53' },
                { caseName: '内马尔', chainShortName: 'ETH', address: '0xc4505db8cc490767fa6f4b6f0f2bdd668b357a5d', content: '0xc4505db8cc490767fa6f4b6f0f2bdd668b357a5d' }
            ],
            isSearchButtonClicked: false,
            selectedChainText:''
        };
    },
    computed: {
        selectedChainImg() {
            const selected = this.chains.find(chain => chain.label === this.selectedChain);
            return selected ? selected.img : '';
        },
        isDisabled() {
            // 当没有选择链或没有输入密钥时，返回 true 使按钮禁用
            return !this.selectedChain || !this.searchKey;
        },
        visibleTags() {
            return this.isMobile
                ? this.getRiskMessage.slice(0, this.maxVisibleTagsMobile)
                : this.getRiskMessage;
        },
        hiddenTagsCount() {
            return this.getRiskMessage.length > this.maxVisibleTagsMobile
                ? this.getRiskMessage.length - this.maxVisibleTagsMobile
                : 0;
        },
        buttonActiveClass() {
            return (caseName, chainShortName, address) => {
                // 只在没有点击搜索按钮时返回激活状态
                if (!this.isSearchButtonClicked) {
                    return this.currentCase === caseName &&
                        this.currentChain === chainShortName &&
                        this.currentAddress === address;
                }
                return false;
            };
        }
    },
    watch: {
        currentAddress(newAddress, oldAddress) {
            if (newAddress !== oldAddress) {
                this.searchKey = newAddress
            }
        },
        currentChain(newChainShortName, oldChainShortName) {
            if (newChainShortName !== null && newChainShortName !== oldChainShortName) {
                this.selectedChain = newChainShortName;
            }

            if (this.selectedChain == 'ETH') {
                this.selectedChainText = 'ETH'
            } else if (this.selectedChain == 'USDT-TRC20') {
                this.selectedChainText = 'USDT'
            } else if (this.selectedChain == 'BTC') {
                this.selectedChainText = 'BTC'
            } else {
                this.selectedChainText = ''
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    created() {
        const category = this.$route.params.category;
        const address = this.$route.params.address;

        if (category && address) {
            this.fullscreenLoading = true
            this.setParameters(category, address);
            this.fullscreenLoading = false
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth <= 768;
        },
        showData(caseName, chainShortName, address) {
            this.currentChain = chainShortName;
            this.selectedChain = chainShortName;
            this.currentCase = caseName;
            this.currentAddress = address;
            this.searchKey = address; // 回显搜索框内容

            // 更新 URL 不带参数
            this.$router.push({ path: '/' });

            this.isSearchButtonClicked = false;
            // 确保视图更新
            this.$nextTick(() => {
                this.searchDataList(this.currentChain, this.currentAddress);
            });
        },
        checkHash(hash, network) {
            switch (network) {
                case 'TRX':
                case 'USDT-TRC20':
                    // TRON address format
                    return /^T[A-Za-z0-9]{33}$/.test(hash);
                case 'ETH':
                case 'ERC20':
                    // ETH and ERC-20 address format
                    return /^0x[a-fA-F0-9]{40}$/.test(hash);
                case 'BTC': {
                    // BTC addresses
                    // Legacy BTC address (P2PKH or P2SH)
                    const legacyBTC = /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(hash);
                    // Bech32 BTC address (SegWit)
                    const bech32BTC = /^(bc1)[a-z0-9]{25,39}$/.test(hash);
                    return legacyBTC || bech32BTC;
                }
                default:
                    return false;
            }
        },
        handleLongPress() {
            // 处理长按事件
            const input = this.$refs.input.$el.querySelector('input');
            if (input) {
                input.select(); // 选择所有文本
            }
        },
        searchData() {
            this.fullscreenLoading = true
            if (!this.checkHash(this.searchKey, this.selectedChain)) {
                this.$message({
                    message: '未找到结果，请检查所选币种或地址格式是否正确',
                    type: 'warning'
                });
                return;
            }
            this.isSearchButtonClicked = true;

            this.searchDataList(this.selectedChain,this.searchKey)
            this.fullscreenLoading = true;
        },

        setParameters(category, address) {
            this.$nextTick(() => {
                this.selectedChain = category;
                this.searchKey = address;
                this.$nextTick(async () => {
                    // 等待状态更新完成
                    console.log('Updated State:', {
                        currentChain: this.selectedChain,
                        currentAddress: this.searchKey
                    });
                    if (this.selectedChain && this.searchKey) {
                            this.searchData(this.selectedChain,this.searchKey);
                    }
                });
            });

        },

        async searchDataList(selectedChain,searchKey) {

            this.fullscreenLoading = true;

            let coin = selectedChain;
            let address = searchKey;
            let api_key = this.apiKey;

            this.getRiskMessage = [];
            const getJyList = `https://openapi.misttrack.io/v1/address_overview?coin=${coin}&address=${address}&api_key=${api_key}`;
            try {
                const response = await axios.get(getJyList, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

                this.checkAddressType(this.searchKey)

                this.getChainsList = response.data.data


                this.riskData();
                this.getTransitionData();

                if (this.getChainsList) {
                    this.getChainsList.first_seen = this.convertUnixTimestampToDate(response.data.data.first_seen)
                    this.getChainsList.last_seen = this.convertUnixTimestampToDate(response.data.data.last_seen)
    
                    let getselectedChain = this.selectedChain
    
                    if (getselectedChain == 'ETH') {
                        this.getSelectionImage = require('../assets/eth.png')
                    } else  if (getselectedChain == 'USDT-TRC20') {
                        this.getSelectionImage = require('../assets/trx.png')
                    } else if (getselectedChain == 'USDT-ERC20') {
                        this.getSelectionImage = require('../assets/erc.png')
                    } else {
                        this.getSelectionImage = require('../assets/btc.png')
                    } 
    
                    setTimeout(() => {
                        this.fullscreenLoading = false;
                    }, 2000);


                } else {
                    // 未找到结果，请检查所选币种或地址格式是否正确
                    this.$message({
                        message: '未找到结果，请检查所选币种或地址格式是否正确',
                        type: 'warning'
                    });
                }
            } catch (error) {
                this.fullscreenLoading = true;
                this.$message({
                    message: '请求多次，请稍后重试',
                    type: 'warning'
                });
            }

        },

        // 时间戳
        convertUnixTimestampToDate(unixTimestamp) {
            // 检查 unixTimestamp 是否为数字并在合理的范围内
            if (typeof unixTimestamp === 'number' && unixTimestamp > 0 && unixTimestamp < 2147483647) {
                const date = new Date(unixTimestamp * 1000);
                const formattedDate = date.toLocaleString();
                return formattedDate;
            }
            // 如果不是有效的时间戳，返回原始数据
            return unixTimestamp;
        },


        // 复制地址的函数
        copyAddress(address) {
            if (navigator.clipboard) {
                // 使用 clipboard API 复制地址
                navigator.clipboard.writeText(address)
                    .then(() => {
                        this.$message({
                            message: '地址复制成功!',
                            type: 'success',
                        });
                    })
                    .catch(err => {
                        console.error('复制失败:', err);
                        this.$message({
                            message: '地址复制失败!',
                            type: 'error',
                        });
                    });
            } else {
                // 备用方案：使用 document.execCommand('copy')
                const textarea = document.createElement('textarea');
                textarea.value = address;
                document.body.appendChild(textarea);
                textarea.select();
                try {
                    document.execCommand('copy');
                    this.$message({
                        message: '地址复制成功!',
                        type: 'success',
                    });
                } catch (err) {
                    console.error('复制失败:', err);
                    this.$message({
                        message: '地址复制失败!',
                        type: 'error',
                    });
                }
                document.body.removeChild(textarea);
            }
        },
        openInNewWindow(category, address) {
            const url = `/address/${category}/${address}`;
            this.currentAddress = address
            window.open(url, '_blank');
        },


        // get风险交易分析接口
        async riskData() {
            if (!this.selectedChain || !this.searchKey) {
                console.error("请选择链并输入搜索密钥。");
                return;
            }

            let coin = this.selectedChain;
            let address = this.searchKey;
            let api_key = 't5Uf7L10jEkXpOxWZ6Bzea4YGuMFcqHN';

            const getJyList = `https://openapi.misttrack.io/v1/risk_score?coin=${coin}&address=${address}&api_key=${api_key}`;

            this.fullscreenLoading = true;

            try {
                const response = await axios.get(getJyList, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });

                this.getRiskList = response.data.data || {}; // 初始化 getRiskList


                // 确保 getRiskList 有 score 属性
                const score = this.getRiskList.score || 0;
                this.getRiskDetails_score = score;

                let getselectedChain = this.selectedChain;
                if (getselectedChain == 'ETH') {
                    this.getSelectionImage = require('../assets/eth.png');
                } else if (getselectedChain == 'USDT-TRC20') {
                    this.getSelectionImage = require('../assets/trx.png');
                } else if (getselectedChain == 'USDT-ERC20') {
                    this.getSelectionImage = require('../assets/erc.png');
                } else {
                    this.getSelectionImage = require('../assets/btc.png');
                }

                // 翻译风险等级为中文
                const riskLevelMap = {
                    Low: '低',
                    Moderate: '适中',
                    High: '高',
                    Severe: '严重'
                };

                // 确保 this.getRiskList 存在并且有 risk_level 和 score
                if (this.getRiskList && this.getRiskList.risk_level) {
                    this.translatedRiskLevel = riskLevelMap[this.getRiskList.risk_level] || '未知';

                    // 设置风险分数和样式类
                    switch (this.getRiskList.risk_level) {
                        case 'Low':
                            this.riskClass = 'green-risk'; 
                            this.riskTextClass = 'green-risk-text'; 
                            break;
                        case 'Moderate':
                            this.riskClass = 'yellow-risk'; 
                            this.riskTextClass = 'yellow-risk-text'; 
                            break;
                        case 'High':
                        case 'Severe':
                            this.riskClass = 'red-risk'; 
                            this.riskTextClass = 'red-risk-text'; 
                            break;
                        default:
                            this.riskClass = '';
                            this.riskTextClass = '';
                            break;
                    }

                    this.riskScore = this.getRiskList.score || 0;
                } else {
                    // 如果 getRiskList 不存在或没有 risk_level，则使用默认值
                    this.translatedRiskLevel = '未知';
                    this.riskClass = '';
                    this.riskTextClass = '';
                    this.riskScore = 0;
                }


                // 获取风险交易详情并转换type为中文
                this.getRiskDetails = this.getRiskList.risk_detail?.map(item => ({
                    ...item,
                    type: this.typeMap[item.type] || item.type, // 处理type字段
                })) || [];

                let detail_list = this.getRiskList.detail_list || [];

                const riskMessagesMap = {
                    "Malicious Address": "恶意地址",
                    "Suspected Malicious Address": "疑似恶意地址",
                    "High-risk Tag Address": "高风险标签地址",
                    "Medium-risk Tag Address": "中等风险标签地址",
                    "Mixer": "混合器",
                    "Sanctioned Entity": "受制裁实体",
                    "Risk Exchange": "风险交换",
                    "Gambling": "赌博",
                    "Involved Theft Activity": "涉及盗窃活动",
                    "Involved Ransom Activity": "涉及赎金活动",
                    "Involved Phishing Activity": "涉及网络钓鱼活动",
                    "Interact With Malicious Address": "与恶意地址交互",
                    "Interact With Suspected Malicious Address": "与可疑恶意地址交互",
                    "Interact With High-risk Tag Address": "与高风险标签交互",
                    "Interact With Medium-risk Tag Addresses": "与中等风险标签交互"
                };

                let risk_messages = [];
                for (let detail of detail_list) {
                    risk_messages.push(riskMessagesMap[detail] || '未知风险');
                }
                this.getRiskMessage = risk_messages;


                // 在数据处理完成后初始化图表
                this.$nextTick(() => {
                    this.initChart();
                    this.fullscreenLoading = false; // 确保在图表渲染后才关闭 loading
                });
            } catch (error) {
                this.$message({
                    message: '请求多次，请稍后重试',
                    type: 'warning'
                });
                this.fullscreenLoading = true; // 出错时关闭 loading
            }
        },

            
        // 简写地址的函数
        shortenAddress(address) {
            return `${address.slice(0, 6)}...${address.slice(-4)}`;
        },

        openDialog() {
            this.dialogVisible = true;
        },

        submitForm() {
            this.dialogVisible = false;
        },

        handleClose() {
            // Handle any additional logic when the dialog is closed
        },

        // 图标
        initChart() {
            const chartDom = document.getElementById('main');
            if (!chartDom) {
                console.warn('Chart DOM element not found');
                return;
            }

            const myChart = echarts.init(chartDom);

            // 获取 risk_level 和 hacking_event
            const { risk_level: riskLevel = '', hacking_event: hackingEvent = false, score = 0 } = this.getRiskList;

            // 确定雷达图的值和颜色
            let radarValue = [30, 30, 30]; // 默认值
            let fillColor = '#4caf50'; // 默认绿色

            // 根据 hackingEvent 和 riskLevel 调整值和颜色
            if (hackingEvent) {
                radarValue = [30, score, 30]; // 黑客事件使用 score
                fillColor = '#f44336'; // 红色
            } else {
                if (riskLevel === 'Low') {
                fillColor = '#4caf50'; // 绿色
                } else if (riskLevel === 'Severe') {
                radarValue = [100, 100, 100]; // 全部设置为高值
                fillColor = '#f44336'; // 红色
                } else {
                fillColor = '#ff9800'; // 橙色
                }
            }

            const option = {
                title: {
                text: ''
                },
                legend: {
                // data: ['风险分析'] // 可选，移除如果不需要
                },
                radar: {
                shape: 'polygon',
                indicator: [
                    { name: '风险实体', max: 100 },
                    { name: '黑客事件', max: 100 },
                    { name: '可疑交易', max: 100 }
                ],
                splitNumber: 5, // 根据需求调整分割数量
                splitLine: {
                    lineStyle: {
                    color: '#ddd'
                    }
                },
                splitArea: {
                    areaStyle: {
                    color: ['#f5f5f5', '#fff']
                    }
                },
                axisLine: {
                    lineStyle: {
                    color: '#ccc'
                    }
                },
                name: {
                    textStyle: {
                    color: '#333',
                    fontSize: 14
                    }
                }
                },
                series: [
                {
                    name: '风险分析',
                    type: 'radar',
                    data: [
                    {
                        value: radarValue,
                        // name: '风险评估' // 可选，移除如果不需要
                    }
                    ],
                    itemStyle: {
                    color: fillColor,
                    borderColor: hackingEvent ? '#d32f2f' : '#388e3c',
                    borderWidth: 2
                    },
                    areaStyle: {
                    opacity: 0.2
                    }
                }
                ]
            };

            myChart.setOption(option);
        },

        checkAddressType() {
            // 地址格式正则表达式
            const ethereumAddressPattern = /^0x[a-fA-F0-9]{40}$/;
            const bitcoinAddressPattern = /^(1|3|bc1)[a-zA-HJ-NP-Z0-9]{25,39}$/;
            const tronAddressPattern = /^T[a-zA-Z0-9]{33}$/;
            const litecoinAddressPattern = /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/;

            // 交易哈希格式正则表达式
            const ethereumTxHashPattern = /^0x[a-fA-F0-9]{64}$/;
            const bitcoinTxHashPattern = /^[a-fA-F0-9]{64}$/;
            const tronTxHashPattern = /^[a-fA-F0-9]{64}$/;
            const litecoinTxHashPattern = /^[a-fA-F0-9]{64}$/;

            if (ethereumAddressPattern.test(this.searchKey)) {
                this.blockchainType = 'ethereum';
                this.addressTypeLabel = '个人地址';
            } else if (ethereumTxHashPattern.test(this.searchKey)) {
                this.blockchainType = 'ethereum';
                this.addressTypeLabel = '交易 Hash';
            } else if (bitcoinAddressPattern.test(this.searchKey)) {
                this.blockchainType = 'bitcoin';
                this.addressTypeLabel = '个人地址';
            } else if (bitcoinTxHashPattern.test(this.searchKey)) {
                this.blockchainType = 'bitcoin';
                this.addressTypeLabel = '交易 Hash';
            } else if (tronAddressPattern.test(this.searchKey)) {
                this.blockchainType = 'tron';
                this.addressTypeLabel = '个人地址';
            } else if (tronTxHashPattern.test(this.searchKey)) {
                this.blockchainType = 'tron';
                this.addressTypeLabel = '交易 Hash';
            } else if (litecoinAddressPattern.test(this.searchKey)) {
                this.blockchainType = 'litecoin';
                this.addressTypeLabel = '个人地址';
            } else if (litecoinTxHashPattern.test(this.searchKey)) {
                this.blockchainType = 'litecoin';
                this.addressTypeLabel = '交易 Hash';
            } else {
                this.blockchainType = '';
                this.addressTypeLabel = '未知类型';
            }
        },

        async getTransitionData(){
            this.fullscreenLoadingA = true;

            let coin = this.selectedChain;
            let address = this.searchKey;
            let api_key = 'yr3MEAU7NnRHP4tma5LGoZvQKC2s6Jg1';

            const getJyList = `https://openapi.misttrack.io/v1/transactions_investigation?coin=${coin}&address=${address}&api_key=${api_key}`;

            try {
                const response = await axios.get(getJyList, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
                const data = response.data.data;

                // 存储所有交易数据
                this.allOutData = data.out.map(item => ({
                ...item,
                    tx_hash_count: item.tx_hash_list.length
                }));

                this.allInData = data.in.map(item => ({
                ...item,
                    tx_hash_count: item.tx_hash_list.length
                }));

                // 更新转入和转出交易的总条目数
                this.totalIn = this.allInData.length;
                this.totalOut = this.allOutData.length;

                // 初始化显示的交易数据
                this.updateDisplayInData();
                this.updateDisplayOutData();
                setTimeout(() => {
                    this.fullscreenLoadingA = false;
                }, 2000);
            } catch (error) {
                this.fullscreenLoadingA = true;
                this.$message({
                    message: '请求多次，请稍后重试',
                    type: 'warning'
                });
            } 
        },
        handleClear() {
            this.searchKey = '';
        // 如果需要在清空输入框后执行其他操作，可以在这里添加逻辑
        },
        handleInPageChange(newPage) {
            this.currentInPage = newPage; // 更新转入交易的当前页码
            this.updateDisplayInData(); // 更新显示的数据
        },
        handleOutPageChange(newPage) {
            this.currentOutPage = newPage; // 更新转出交易的当前页码
            this.updateDisplayOutData(); // 更新显示的数据
        },
        updateDisplayInData() {
            const start = (this.currentInPage - 1) * this.pageSize;
            const end = start + this.pageSize;

            // 根据转入交易的当前页码计算显示的数据
            this.displayInData = this.allInData.slice(start, end);
        },
        updateDisplayOutData() {
            const start = (this.currentOutPage - 1) * this.pageSize;
            const end = start + this.pageSize;

            // 根据转出交易的当前页码计算显示的数据
            this.displayOutData = this.allOutData.slice(start, end);
        },
    }
};
</script>

<style scoped>
.el-row {
    text-align: left;
}

.getSearch-box {
    height: 3rem;
}

.getSearch-ul {
    overflow: hidden;
    padding: 0;
    margin: 0;
}


::v-deep .el-loading-parent--relative {
    height: 25rem;
}

::v-deep .el-loading-mask {
    height: 35rem;
}

/* .el-icon svg {
    height: 1px;
    width: 1px;
} */

.getSearch-ul li {
    float:left;
    list-style:none;
}

.getSearch-selected {
    width: 20%;
}

.getSearch-input {
    width: 65%;
}

.getSearch-button {
    width: 15%;
}

.getSearch-button .el-button:disabled {
  background-color: #d3d3d3 !important; /* 禁用状态的背景色 */
  border-color: #d3d3d3 !important; /* 禁用状态的边框色 */
  color: #fff !important; /* 禁用状态的文字颜色 */
}

.getSearch-detail-top-box {
    padding: 31px;
    border-radius: 30px;
    /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.detail-top-box-title {
    padding-left: 10px;
}

.chains-name-text {
    font-size: 19px;
    font-weight: bold;
    margin-right: 10px;
}

.chains-address-text {
    font-size:15px;
    color: #888;
}

.chains-icon {
    width: 50px;
    height: 50px;
    margin-right: 5px;
}


.getSearch-text-one {
    padding: 31px;
    border-radius: 30px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.custom-popover .popover-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; 
}


/* 使用 /deep/ 伪类选择器 */
::v-deep .el-select__wrapper {
  height: 3.15rem; /* 设置最大高度 */
  font-size: 18px;
}

::v-deep .el-select__wrapper:hover {

}

.el-select-dropdown__item {
    justify-content: start;
    height: 61px;
    line-height: 70px;
    font-size: 18px;
    display: flex;
    align-items: center;
}

::v-deep .el-input__wrapper {
    height: 3rem;
    font-size: 18px;
    /* box-shadow: none; */
}

::v-deep .el-button--large {
    --el-button-size: 50px;
    width: 100%;
    background: #000;
    color: #fff;
    font-size: 18px;
}

.item {
    margin-right: 10px;
}

.active {
    background-color: #000 !important; /* 激活时的背景色 */
    color: white !important; /* 激活时的文字颜色 */
}

.search-section {
    margin-bottom: 1rem;
}

.chain-icon {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}

.red-risk-box {
    width: 80px;
    text-align: center;
    align-items: center;
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 9;
    color: #fff; /* 文字颜色为白色 */
}

.green-risk {
    background-color: #4caf50; /* 绿色背景 */
}

.yellow-risk {
    background-color: #ff9800; /* 黄色背景 */
}

.red-risk {
    background-color: #f44336; /* 红色背景 */
}

.green-risk-text {
    color: #4caf50; /* 文字色为绿色 */
}

.yellow-risk-text {
    color: #ff9800; /* 文字色为黄色 */
}

.red-risk-text {
    color: #f44336; /* 文字色为红色 */
}

.red-risk-text-one {
    font-size: 14px;
    padding: 3px 0;
    border-top: 1px solid #e7e7e7;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    margin: 0
}

.risk-text-two-p {
    width: 100%;
    color: #fff;
    font-size: 18px;
    margin: 0;
    height: 30px;
    line-height: 30px;
}


.getSearch-detail-box {
    background: rgb(238, 238, 238);
    border-radius: 25px;
    width: 90%;
    margin: 0 auto;
}

.detail-box-right {
    text-align: right;
}

::v-deep .el-loading-spinner .circular {
    width:20px !important;
}


.detail-button-box {
    padding: 0;
    margin-right: 20px;
    margin-top: 4px;
    color: #999;
    font-size: 14px;
}

.el-button:hover {
    background: #444;
    color: #fff;
    border:1px solid #000;
}

.getSearch-list-box {
    padding: 31px;
    border-radius: 30px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.grid-title {
    font-size: 17px;
    font-weight: bold;
}

.grid-text {
    font-size: 14px;
    color: gray;
}

.getCases-list {
    height: 2rem;
    width: 100%;
}

.clear-getcases {
    overflow: hidden;
}

.cases-top-box {
    float:left;
    text-align: left;
}

.cases-bottom-box {
    float: left;
    text-align: left;
}

.cases-button {
    background: #f9f9f9;
    color: #000;
}

.cases-title {
    font-weight: bold;
}

.cases-title-bottom {
    font-weight: bold;
    margin-left:1rem;
}

.tag-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.tag-item {
  flex: 0 0 auto;
}

.ellipsis {
  flex: 0 0 auto;
  margin-left: 8px;
  color: #f56c6c; /* 与标签颜色匹配 */
}

.detail-box-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cases-button.active {
    background-color: black; 
    color: white; 
}

@media screen and (max-width:768px){ 
    .getSearch-ul {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .tag-item {
        font-size: 9px;
    }
    ::v-deep .el-select__wrapper {

        font-size: 0;
        gap: 0;
    }
    .getSearch-input {
        width: 65%;
    }

    .detail-top-box-title {
        padding-left: 0;
    }

    .getSearch-detail-box {
        width: 100%;
        margin: 0 auto;
    }
    .getSearch-input input:focus {
        background-color: #f0f0f0; /* 背景色高亮 */
        border: 1px solid #ddd; /* 边框高亮 */
    }
    .chains-address-keys {
        font-size: 12px;
    }

    .getSearch-text-one{
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
        margin: 0px;
    }

    .getSearch-list-box {
        padding: 0px;
        border-radius: 0px;
        box-shadow: none;
        margin: 0px;
        margin-top: 2rem;
    }

    #main {
        position: relative;
    /* right: 2.5rem; */
    width: 100% !important;
    margin: 0 auto;
    height: 300px;
    display: flow-root list-item;
    }

    .detail-button-box {
        margin-top: 0;
        font-size: 12px;
    }

    .el-select-dropdown__item {
        justify-content: start;
        height: 61px;
        line-height: 70px;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    ::v-deep .el-input__wrapper {
        height: 3rem;
        font-size: 12px;
    }

    ::v-deep .el-button--large {
        --el-button-size: 43px;
        padding: 24px 0;
        width: 100%;
    }

    .chain-icon {
        width: 30px;
        height: 30px;
        margin-right: 0;
    }

    .grid-title {
        font-size: 15px;
        font-weight: bold;
    }

    .grid-text {
        font-size: 14px;
        color: gray;
    }

    .detail-in-text {
        margin-right: 10px;
    }

    ::v-deep .el-dialog {
        --el-dialog-width: 95%;
    }

    .getSerach-button-span {
        display: none;
    }

    .el-button.is-round {
        border-radius: 0;
    }

    .el-button--large.is-round {
        padding: 24px 26px;
    }

    .el-select__placeholder {
        display: none;
    }

    .getCases-list {
        height: 9rem;
    }

    .getSearch-list-title {
        text-align: left;
    }

    .cases-top-box {
        width: 100%;
        line-height: 53px;
    }

    .cases-bottom-box {
        width: 100%;
        /* margin-top: 1rem; */
    }

    .cases-button {
        margin-top: 5px;
        font-size: 12px;
        width: 102px;
        margin-left: 13px;
    }

    .cases-title {
        font-size: 12px;
    }

    .cases-title-bottom {
        font-size: 12px;
        margin-left: 0;
    }
    .el-col-2 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

</style>
